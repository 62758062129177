<script setup>
import GinrinLogo from "~/components/logos/GinrinLogo.vue";
import FacebookLogo from "~/components/logos/FacebookLogo.vue";
import InstagramLogo from "~/components/logos/InstagramLogo.vue";
import YouTubeLogo from "~/components/logos/YouTubeLogo.vue";
import ShippingIcon from '~/components/icons/ShippingIcon.vue'
import WarrantlyIcon from '~/components/icons/WarrantlyIcon.vue'
import PaymentIcon from '~/components/icons/PaymentIcon.vue'
import SupportIcon from '~/components/icons/SupportIcon.vue'

const dairamon = useDairamon();
const { data } = await dairamon.menus.list({ type_id: [4]});
const menu = ref(data.value.menus);

const gridData = [
  {
    name: '',
    items: [
      'Rólunk',
      'Adatvédelem',
      'Vásárlási feltételek',
    ]
  },
  {
    name: 'Vásárlás',
    items: [
      'Rendeléseim',
      'Visszáru',
      'GYIK',
      'Kapcsolat'
    ]
  },
  {
    name: 'Információk',
    items: [
      'Rólunk',
      'Adatvédelem',
      'Vásárlási feltételek',
    ]
  },
  {
    name: 'Segítség',
    items: [
      'Rendeléseim',
      'Visszáru kezelés',
      'Gyakran Ismételt Kérdések',
      'Kapcsolat'
    ]
  },
];


</script>

<template>
  <section class="footer">
    <div class="wrapper">
      <nav class="footer-nav">
        <div class="column company">
          <div class="company-logo"><img src="/images/logo_light.png" alt=""></div>
          <div class="social">
            <h4>Kövess Minket</h4>
            <NuxtLink to="https://www.youtube.com" target="_blank"><YouTubeLogo class="logo" ></YouTubeLogo></NuxtLink>
            <NuxtLink to="https://www.facebook.com" target="_blank"><FacebookLogo class="logo" ></FacebookLogo></NuxtLink>
            <NuxtLink to="https://www.instagram.com" target="_blank"><InstagramLogo class="logo" ></InstagramLogo></NuxtLink>
          </div>
        </div>
        <div class="column">
          <h3>{{ gridData[1].name }}</h3>
          <ul>
            <li v-for="item in gridData[1].items">
              <NuxtLink>{{ item }}</NuxtLink>
            </li>
          </ul>
        </div>
        <div class="column">
          <h3>{{ gridData[2].name }}</h3>
          <ul>
            <li v-for="item in gridData[2].items">
              <NuxtLink>{{ item }}</NuxtLink>
            </li>
          </ul>
        </div>
        <div class="column">
          <h3>{{ gridData[3].name }}</h3>
          <ul>
            <li v-for="item in gridData[3].items">
              <NuxtLink>{{ item }}</NuxtLink>
            </li>
          </ul>
        </div>
      </nav>
      <div class="footer-bar">
        <p class="copyright">Copyright © 2001-2023 Arctext</p>
        <div class="author">
          <p>Made by</p>
          <NuxtLink to="https://www.ginrin.com" target="_blank"><GinrinLogo class="logo" ></GinrinLogo></NuxtLink>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
@import "@/styles/base/breakpoints.scss";

.footer {
  
  background-color: var(--color1);

  a {
    color: var(--color4);
  }
  h3, h4 {
    color: var(--color4);
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
  
  li {
    line-height: 2.5rem;
  }

  .wrapper {
    margin: auto;
    max-width: var(--max-page-width);
    padding: 1rem 2rem;
    
    .footer-nav {
      display: grid;
      gap: 1rem;
      
      .column {
        padding: 2rem 0 0 0;
        display: flex;
        flex-direction: column;
      }
      
      .column:first-child {
        align-content: flex-start;
        justify-content: space-between;
        align-items: center;
        
        .company-logo {
          margin-bottom: 3rem;
        }
        
        svg {
          margin-right: .8rem;
        }
      }
    }
    
    .footer-bar {
      
      display: grid;
      justify-content: center;
      
      margin-top: 3rem;
      padding: 1.5rem 0;
      
      border-top: solid;
      border-color: #2c2c2c;
      p {
        color: var(--color4);
        font-size: 1.4rem;
      }
      
      .author {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          font-size: 1.2rem;
        }
        .logo {
          margin-top: .3rem;
          margin-left: 1rem;
          width: auto;
          height: 2rem;
        }
      }
    }
  }
}

/* DESKTOP */
@include breakpoint('m') {
  .footer {
    flex-direction: column;
    padding: 0;
    
    .wrapper {
      
      .footer-nav {
        
        grid-template-columns: repeat(4, minmax(0, 1fr));
        
        .column:first-child {
          align-items: unset;
          .company-logo {
            margin-bottom: 3rem;
            img {
              height: 2.5rem;
            }
          }
          .social h4 {
            text-align: left;
          }
          
          svg {
            margin-right: .8rem;
          }
        }
      }
      
      .footer-bar {
        display: flex;
        justify-content: space-between;
       
      }
    }
  }

}



</style>
