<script setup>
const props = defineProps({
  color: { type: String, default: '#ffffff' },
})

const dairamon = useDairamon();
const color = ref(props.color)

const setDefaultColor = () => {
  color.value = dairamon.style.getPropertyValue('--icon-default-color');
}

onMounted(() => {
  setDefaultColor();
})

</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9997 4.86664C3.23638 4.86664 3.08301 5.67632 3.08301 12C3.08301 18.3237 3.23638 19.1334 11.9997 19.1334C20.7631 19.1334 20.9165 18.3237 20.9165 12C20.9165 5.67632 20.7631 4.86664 11.9997 4.86664ZM14.8575 12.3094L10.8539 14.2512C10.5035 14.4198 10.2164 14.2308 10.2164 13.8288V10.1713C10.2164 9.77014 10.5035 9.58023 10.8539 9.74884L14.8575 11.6906C15.208 11.8611 15.208 12.139 14.8575 12.3094Z" :fill="color"/>
  </svg>
</template>

<style scoped>
</style>
