<script setup>
const props = defineProps({
  color: { type: String, default: '#ffffff' },
})

const dairamon = useDairamon();
const color = ref(props.color)

const setDefaultColor = () => {
  color.value = dairamon.style.getPropertyValue('--icon-default-color');
}

onMounted(() => {
  setDefaultColor();
})

</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 12.0503C3 16.5248 6.24975 20.2455 10.5 21V14.4998H8.25V12H10.5V9.99975C10.5 7.74975 11.9497 6.50025 14.0002 6.50025C14.6497 6.50025 15.3503 6.6 15.9998 6.69975V9H14.85C13.7498 9 13.5 9.54975 13.5 10.2502V12H15.9L15.5002 14.4998H13.5V21C17.7502 20.2455 21 16.5255 21 12.0503C21 7.0725 16.95 3 12 3C7.05 3 3 7.0725 3 12.0503Z" :fill="color"/>
  </svg>
</template>

<style scoped>
</style>
