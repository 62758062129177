<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.77581 5.77568C6.16633 5.38516 6.7995 5.38516 7.19002 5.77568L18.2244 16.8101C18.615 17.2006 18.615 17.8338 18.2244 18.2243C17.8339 18.6148 17.2008 18.6148 16.8102 18.2243L5.77581 7.18989C5.38528 6.79937 5.38528 6.16621 5.77581 5.77568Z" fill="#090909"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.2244 5.77568C18.615 6.16621 18.615 6.79937 18.2244 7.1899L7.19002 18.2243C6.79949 18.6148 6.16633 18.6148 5.7758 18.2243C5.38528 17.8338 5.38528 17.2006 5.7758 16.8101L16.8102 5.77568C17.2008 5.38516 17.8339 5.38516 18.2244 5.77568Z" fill="#090909"/>
  </svg>

</template>

<script>
export default {
  name: "ExitIcon"
}
</script>

<style scoped>
</style>








