<script setup>
import MagnifyIcon from "~/components/icons/MagnifyIcon.vue";
import FavIcon from "~/components/icons/FavIcon.vue";
import UserIcon from "~/components/icons/UserIcon.vue";
import CartIcon from "~/components/icons/CartIcon.vue";
import HamburgerIcon from '~/components/icons/HamburgerIcon.vue'
import ExitIcon from '~/components/icons/ExitIcon.vue'
import LoginModal from "~/components/modals/LoginModal.vue";
import RegisterModal from "~/components/modals/RegisterModal.vue";

const dairamon = useDairamon();
const { data } = await dairamon.menus.list({ type_id: [1]});
const { data: categories } = await dairamon.categories.list();

dairamon.storage.setItem('categories', categories.value.data);

const mainMenu = ref(data.value.menus);
const isNavToggled = ref(false);
const loginModal = ref(false);
const registerModal = ref(false);
const onLogin = async () => {
  
  const login = await dairamon.auth.signIn({
    username: 'test@elek.hu',
    password: 'tesztjelszó',
    redirect: false
  });
  if (login) console.log('Its success', login.id)
}

const onLogOut = async () => {
  
  const logout = await dairamon.auth.signOut({ redirect: false });
  console.log('Dairamon LogOut', logout);
  
}

const onSession = async () => {
  
  const session = await dairamon.auth.getSession();
  console.log('Dairamon Session', session);
}

const userIconAction = () => {
  let userId = dairamon.userId() ?? false;

  if (!userId) {
    registerModal.value = true;
  }
}

const hasRegisteredUser = () => {
  registerModal.value = false;
  loginModal.value = true;
}

const breakpoint = () => {
  return dairamon.style.getPropertyValue('--breakpoint').replace(/["]/g, '');
}

const onNavToggle = () => {
  //console.log(breakpoint())
  if (breakpoint() === 'l' || breakpoint() === 'xl' || breakpoint() === 'xxl') return
  console.log('Do you thing', breakpoint())
  const header = document.querySelector('header');
  const buttons = document.querySelectorAll('.nav-button');
  const icons = document.querySelectorAll('.icon');
  isNavToggled.value = !isNavToggled.value;
  header.setAttribute('data-visible', isNavToggled.value);
  buttons.forEach(button => {
    button.setAttribute('data-visible', isNavToggled.value);
  });
  icons.forEach(icon => {
    icon.setAttribute('data-visible', isNavToggled.value);
  });
}

</script>

<template>
  <header>
    <nav>
      <ul class="primary-nav">
        <li><NuxtLink @click="onNavToggle" to="/"><img alt='logo' class="logo" src="/images/logo.png"></NuxtLink></li>
        <li v-for="item in mainMenu">
          <NuxtLink @click="onNavToggle" class='nav-button' :to="'/' + item.menu_url" >{{ item.menu_name }}</NuxtLink>
        </li>
      </ul>
    </nav>
    <div class="icons">
      <MagnifyIcon class="icon" @click="onLogOut"></MagnifyIcon>
      <UserIcon class="icon link" @click="userIconAction"></UserIcon>
      <FavIcon class="icon" @click="onSession"></FavIcon>
      <CartIcon class="icon"> </CartIcon>
      <HamburgerIcon v-if="!isNavToggled" @click="onNavToggle" class="mobile-nav-toggle"> </HamburgerIcon>
      <ExitIcon v-if="isNavToggled" @click="onNavToggle" class="mobile-nav-toggle"> </ExitIcon>
    </div>
  </header>
  <LoginModal v-if="loginModal" @close="loginModal = false"></LoginModal>
  <RegisterModal v-if="registerModal" @close="registerModal = false"></RegisterModal>
</template>

<style scoped lang="scss">
@import "@/styles/base/breakpoints.scss";

header {
  padding: 2rem;
  display: flex;
  //width: 100%;
  margin: auto;
  align-items: flex-start;
  height: 6rem;
  
  &[data-visible="true"] {
    height: 100vh;
  }
  
  .primary-nav {
    display: flex;
    flex-direction: column;
    gap: 6rem;
    
    .logo {
      height: 2.5rem;
    }
    
    .nav-button {
      display: none;

      &[data-visible="true"] {
        position: absolute;
        display: grid;
      }
    }
    
  }
  
  .icons {
    z-index: 1;
    display: flex;
    margin-left: auto;
    gap: 2rem;
    align-items: center;

    .icon {
      opacity: 1;
      color: var(--color1);


      &.link:hover {
        color: var(--icon-hover-color);
      }
      
      &[data-visible="true"] {
        opacity: 0;
      }
    }
  }
  
  svg {
    cursor: pointer;
  }
}

/* Desktop */
@include breakpoint('l') {
  
  header {
    
    max-width: var(--max-page-width);
    
    height: 6rem;
    
    &[data-visible="true"] {
      height: 6rem;
    }
    
    .primary-nav {
      display: flex;
      flex-direction: row;
      gap: 1.2rem;
      
      .logo {
        height: 2rem;
      }
      
      .nav-button {
        display: block;
      }
      
      .router-link-active::before {
        transform: translate(0, -2rem);
      }
    }
    
    svg {
      opacity: 1;
    }
    
    .icons {
      .icon {
        opacity: 1;
        
        &[data-visible="true"] {
          opacity: 1;
        }
      }
    }
    
    .mobile-nav-toggle {
      display: none;
    }
  }
}

</style>
