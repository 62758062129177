<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.55713 4.99999C2.55713 4.53055 2.93769 4.14999 3.40713 4.14999H5.34126C5.73194 4.14999 6.07231 4.4163 6.1663 4.7955L8.38834 13.7606H16.9228L18.4712 8.24736H8.80234C8.3329 8.24736 7.95234 7.8668 7.95234 7.39736C7.95234 6.92791 8.3329 6.54736 8.80234 6.54736H19.5928C19.8588 6.54736 20.1095 6.67192 20.2702 6.88396C20.4309 7.096 20.483 7.37104 20.4111 7.62718L18.3854 14.8404C18.2824 15.2071 17.9479 15.4606 17.567 15.4606H7.7233C7.33262 15.4606 6.99225 15.1943 6.89826 14.8151L4.67622 5.84999H3.40713C2.93769 5.84999 2.55713 5.46944 2.55713 4.99999ZM9.30123 17.8368C8.95656 17.8368 8.62428 18.1357 8.62428 18.571C8.62428 19.0062 8.95656 19.3051 9.30123 19.3051C9.6459 19.3051 9.97818 19.0062 9.97818 18.571C9.97818 18.1357 9.6459 17.8368 9.30123 17.8368ZM6.92428 18.571C6.92428 17.2563 7.95928 16.1368 9.30123 16.1368C10.6432 16.1368 11.6782 17.2563 11.6782 18.571C11.6782 19.8856 10.6432 21.0051 9.30123 21.0051C7.95928 21.0051 6.92428 19.8856 6.92428 18.571ZM16.0808 17.8368C15.7361 17.8368 15.4038 18.1357 15.4038 18.571C15.4038 19.0062 15.7361 19.3051 16.0808 19.3051C16.4255 19.3051 16.7577 19.0062 16.7577 18.571C16.7577 18.1357 16.4255 17.8368 16.0808 17.8368ZM13.7038 18.571C13.7038 17.2563 14.7388 16.1368 16.0808 16.1368C17.4227 16.1368 18.4577 17.2563 18.4577 18.571C18.4577 19.8856 17.4227 21.0051 16.0808 21.0051C14.7388 21.0051 13.7038 19.8856 13.7038 18.571Z" fill="#090909"/>
  </svg>
</template>

<script>
export default {
  name: "CartIcon"
}
</script>

<style scoped>
</style>








