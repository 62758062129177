<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9785 6C10.2577 6 8.86268 7.39499 8.86268 9.11579C8.86268 10.7643 10.143 12.1138 11.7635 12.2243C11.8349 12.2224 11.9066 12.2214 11.9785 12.2214C12.0504 12.2214 12.1221 12.2224 12.1935 12.2243C13.814 12.1138 15.0943 10.7643 15.0943 9.11579C15.0943 7.39499 13.6993 6 11.9785 6ZM15.3468 12.9663C16.4178 12.0286 17.0943 10.6512 17.0943 9.11579C17.0943 6.29042 14.8038 4 11.9785 4C9.1531 4 6.86268 6.29042 6.86268 9.11579C6.86268 10.6512 7.53911 12.0287 8.61022 12.9664C5.88823 14.2373 4 17 4 20.1999C4 20.7522 4.44772 21.1999 5 21.1999C5.55228 21.1999 6 20.7522 6 20.1999C6 16.9812 8.55164 14.3516 11.7399 14.2261C11.819 14.2297 11.8985 14.2316 11.9785 14.2316C12.0584 14.2316 12.138 14.2297 12.217 14.2261C15.4054 14.3516 17.957 16.9812 17.957 20.1999C17.957 20.7522 18.4047 21.1999 18.957 21.1999C19.5093 21.1999 19.957 20.7522 19.957 20.1999C19.957 17 18.0688 14.2373 15.3468 12.9663Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: "UserIcon"
}
</script>

<style scoped>
</style>








