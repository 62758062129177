<script setup lang="ts">
import Header from "~/components/Header.vue";
import Footer from "~/components/Footer.vue";

const dairamon = useDairamon();

onMounted(async() => {
  sessionStorage.removeItem("user_id");
  const session = await dairamon.auth.getSession();
  let id = session?.id;

  if (id)
    window.sessionStorage.setItem("user_id",id);
})
</script>

<template>
  <div>
    <Header/>
    <NuxtPage/>
    <Footer/>
  </div>
</template>
