<script setup>
const dairamon = useDairamon();
const emit = defineEmits(['close']);

const email = ref("");
const firstname = ref("");
const lastname = ref("");
const passwordConf = ref("");
const password = ref("");

const submit = async () => {

}

</script>

<template>
  <DModal :open="true" @close="$emit('close')">
    <template v-slot:title>Regisztráció</template>

    <form class="login-form" @submit.prevent="submit">
      <div class="input-holder">
        <label for="firstname">Vezetéknév</label>
        <input type="text" name="firstname" v-model="firstname">
      </div>
      <div class="input-holder">
        <label for="lastname">Keresztnév</label>
        <input type="text" name="lastname" v-model="lastname">
      </div>

      <div class="input-holder">
        <label for="email">Email</label>
        <input type="email" name="email" v-model="email">
      </div>
      <div class="input-holder">
        <label for="password">Jelszó</label>
        <input type="password" name="password" v-model="password">
      </div>
      <div class="input-holder">
        <label for="password">Jelszó újra</label>
        <input type="password" name="password" v-model="passwordConf">
      </div>

      <div class="login-buttons">
        <button type="button" class="button forgot">Van már fiókom</button>
        <button type="submit" class="button login">Regisztrálok</button>
      </div>
    </form>
  </DModal>
</template>

<style scoped lang="scss">
.login-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 10px;
  margin-top: 15px;

  .input-holder {
    display: flex;
    flex-direction: column;
    gap: 8px;

    label {
      font-family: 'Be Vietnam Pro', sans-serif;
      font-size: 11px;
      line-height: 11px;
    }

    input {
      width: 100%;
      border: 1px solid #B6B6B6;
      border-radius: 8px;
      padding: 17px 16px 17px 14px;
      outline: none;
      font-size: 14px;
      font-family: 'Be Vietnam Pro', sans-serif;
      line-height: 14px;
    }
  }

  .login-buttons {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;

    .button {
      background: transparent;
      border: none;
      cursor: pointer;
      font-family: 'Be Vietnam Pro', sans-serif;
      font-weight: 700;
      font-size: 14px;
      padding: 14px;

      &.forgot {
        font-size: 11px;
        color: var(--color1);
        margin-left: -14px;
      }

      &.login {
        color: white;
        background: var(--color1);
        padding: 14px 32px;
        border-radius: 60px;
      }
    }
  }
}
</style>