<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 6.60205C8.23858 6.60205 6 8.84063 6 11.6021C6 14.3635 8.23858 16.6021 11 16.6021C13.7614 16.6021 16 14.3635 16 11.6021C16 8.84063 13.7614 6.60205 11 6.60205ZM4 11.6021C4 7.73606 7.13401 4.60205 11 4.60205C14.866 4.60205 18 7.73606 18 11.6021C18 13.1744 17.4816 14.6256 16.6064 15.7942L20.7071 19.895C21.0976 20.2855 21.0976 20.9186 20.7071 21.3092C20.3166 21.6997 19.6834 21.6997 19.2929 21.3092L15.1922 17.2084C14.0236 18.0836 12.5723 18.6021 11 18.6021C7.13401 18.6021 4 15.468 4 11.6021Z" fill="#090909"/>
  </svg>
</template>

<script>
export default {
  name: "MagnifyIcon"
}
</script>

<style scoped>
</style>








