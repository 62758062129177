<script setup>
const props = defineProps({
  color: { type: String, default: '#ffffff' },
})

const dairamon = useDairamon();
const color = ref(props.color)

const setDefaultColor = () => {
  color.value = dairamon.style.getPropertyValue('--icon-default-color');
}

onMounted(() => {
  setDefaultColor();
})

</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9978 9.3322C10.5289 9.3322 9.3301 10.531 9.3301 12C9.3301 13.469 10.5289 14.6678 11.9978 14.6678C13.4668 14.6678 14.6656 13.469 14.6656 12C14.6656 10.531 13.4668 9.3322 11.9978 9.3322ZM19.9991 12C19.9991 10.8953 20.0091 9.80051 19.9471 8.69777C19.885 7.4169 19.5928 6.28014 18.6562 5.3435C17.7176 4.40487 16.5829 4.11467 15.302 4.05263C14.1973 3.99059 13.1026 4.0006 11.9999 4.0006C10.8951 4.0006 9.80041 3.99059 8.69768 4.05263C7.41684 4.11467 6.28009 4.40687 5.34348 5.3435C4.40486 6.28214 4.11467 7.4169 4.05263 8.69777C3.99059 9.80252 4.0006 10.8973 4.0006 12C4.0006 13.1027 3.99059 14.1995 4.05263 15.3022C4.11467 16.5831 4.40686 17.7199 5.34348 18.6565C6.28209 19.5951 7.41684 19.8853 8.69768 19.9474C9.80241 20.0094 10.8971 19.9994 11.9999 19.9994C13.1046 19.9994 14.1993 20.0094 15.302 19.9474C16.5829 19.8853 17.7196 19.5931 18.6562 18.6565C19.5948 17.7179 19.885 16.5831 19.9471 15.3022C20.0111 14.1995 19.9991 13.1047 19.9991 12V12ZM11.9978 16.1048C9.72636 16.1048 7.89315 14.2715 7.89315 12C7.89315 9.72847 9.72636 7.89523 11.9978 7.89523C14.2693 7.89523 16.1025 9.72847 16.1025 12C16.1025 14.2715 14.2693 16.1048 11.9978 16.1048ZM16.2707 8.68576C15.7403 8.68576 15.312 8.25747 15.312 7.72711C15.312 7.19675 15.7403 6.76847 16.2707 6.76847C16.801 6.76847 17.2293 7.19675 17.2293 7.72711C17.2294 7.85305 17.2048 7.97778 17.1566 8.09416C17.1085 8.21054 17.0379 8.31628 16.9489 8.40533C16.8598 8.49438 16.7541 8.56499 16.6377 8.61311C16.5213 8.66123 16.3966 8.68592 16.2707 8.68576V8.68576Z" :fill="color"/>
  </svg>
</template>

<style scoped>
</style>
