<template>
  <div v-if="open" class="d-modal" @click.self="$emit('close')">
    <div class="d-modal-inner" :class="[size]">
      <div class="d-modal-header">
        <div class="d-modal-title"><slot name="title">Modal</slot></div>

        <svg class="d-modal-close-icon" @click="$emit('close')" width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.7382 7.23832C7.19381 6.7827 7.9325 6.7827 8.38812 7.23832L21.2616 20.1118C21.7172 20.5674 21.7172 21.3061 21.2616 21.7617C20.806 22.2173 20.0673 22.2173 19.6117 21.7617L6.7382 8.88823C6.28259 8.43262 6.28259 7.69393 6.7382 7.23832Z" fill="currentColor"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M21.2616 7.23832C21.7172 7.69393 21.7172 8.43262 21.2616 8.88823L8.38811 21.7617C7.9325 22.2173 7.19381 22.2173 6.73819 21.7617C6.28258 21.3061 6.28258 20.5674 6.73819 20.1118L19.6117 7.23832C20.0673 6.7827 20.806 6.7827 21.2616 7.23832Z" fill="currentColor"/>
        </svg>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: "DModal",
  emits: ["close"],
  props: ["open","size"],
  methods: {
    escapeEvent(e) {
      if (e.key === 'Escape')
        this.$emit('close');
    }
  },

  mounted() {
    window.addEventListener("keyup",this.escapeEvent)
  },
  beforeUnmount() {

    window.removeEventListener("keyup",this.escapeEvent)
  }
}
</script>

<style scoped lang="scss">
.d-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background: #1C1C1CB2;
  z-index: 99999999;

  .d-modal-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 400px;
    background: white;
    border-radius: 10px;
    min-height: 200px;
    transform: translateX(-50%) translateY(-50%);
    padding: 45px 50px 50px 50px;

    &.medium {
      max-width: 600px;
    }

    &.large {
      max-width: 800px;
    }

    .d-modal-header {
      width: 100%;
      padding-bottom: 10px;
      border-bottom: 1px solid #DDDDDD;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      font-family: 'Be Vietnam Pro', sans-serif;
      font-size: 23px;
      color: var(--color1);

      .d-modal-close-icon {
        cursor: pointer;
        &:hover {
          color: var(--icon-hover-color);
        }
      }
    }
  }


}
</style>